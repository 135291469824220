import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/f8df4ce0-3c24-4c0b-9517-0456fa889a19/components/global/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/f8df4ce0-3c24-4c0b-9517-0456fa889a19/components/global/ProgressBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/f8df4ce0-3c24-4c0b-9517-0456fa889a19/components/global/RealUserMonitoring.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/f8df4ce0-3c24-4c0b-9517-0456fa889a19/components/global/WebVitals.tsx");
;
import(/* webpackMode: "eager" */ "/build/f8df4ce0-3c24-4c0b-9517-0456fa889a19/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Marianne/static/Marianne-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Bold.woff2\",\"weight\":\"700\"}],\"fallback\":[\"arial\"]}],\"variableName\":\"marianne\"}");
;
import(/* webpackMode: "eager" */ "/build/f8df4ce0-3c24-4c0b-9517-0456fa889a19/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/build/f8df4ce0-3c24-4c0b-9517-0456fa889a19/styles/typography.css");
